import { Component, OnInit } from '@angular/core';
import { IssueType } from '@shared/classes/model/backend/issue';

import { Router } from '@angular/router';
import { LocaleService } from '@core/services/locale/locale.service';
import { AppDialogService } from '@shared/services/app-dialog.service';
import { ExportDataService } from '@shared/services/export-data.service';
import { IssueTypeMappingService } from '@shared/services/mappings/issue/issue-type-mapping.service';
import { AppInjector } from 'app/app-injector';
import {
  ActionTypesEnum,
  ButtonColor,
  makePlural,
  ModuleKeywordRootPath,
  ModuleKeywords,
  ModuleRoutePrefix,
  PermissionActions,
  routeToLocaleCase,
  toCamelCase,
  toKebabCase,
} from 'app/shared/classes';
import { BaseTablePage } from 'app/shared/classes/view/BaseTablePage';
import { IssueTypeDataService } from '../../services/data/issue-type-data.service';

@Component({
  selector: 'app-issue-types-list',
  templateUrl: './issue-types-list.component.html',
  styleUrls: ['./issue-types-list.component.scss'],
})
export class IssueTypesListComponent extends BaseTablePage<IssueType> implements OnInit {
  constructor(
    private requestService: IssueTypeDataService,
    exportService: ExportDataService,
    appDialogService: AppDialogService,
    router: Router,
    public localeService: LocaleService
  ) {
    super(
      requestService,
      exportService,
      appDialogService,
      router,
      {
        moduleKeyword: ModuleKeywords.IssueType,
        routePrefix: ModuleRoutePrefix.IssueManagement,
      },
      localeService
    );
    this.SetPageValues({
      breadCrumb: {
        items: [
          {
            label: this.localeService.translate(
              `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(ModuleKeywordRootPath[this.moduleKeyword])}`
            ),
            routerLink: [`${this.routePrefix ? this.routePrefix : toKebabCase(this.moduleKeyword)}`],
          },
          {
            label: this.localeService.translate(
              `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(this.moduleKeyword)}.${makePlural(toCamelCase(this.moduleKeyword))}`
            ),
            routerLink: [`/${this.routePrefix}${toKebabCase(this.moduleKeyword)}/list`],
          },
        ],
      },
      pageActions: [
        {
          label:
            this.localeService.translate('general.actions.add') +
            ' ' +
            this.localeService.translate(
              `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(this.moduleKeyword)}.${toCamelCase(this.moduleKeyword)}`
            ),
          id: 1,
          type: ActionTypesEnum.Add,
          color: ButtonColor.Primary,
          icon: 'pi pi-plus',
          iconPos: 'left',
          command: this.addNewItem.bind(this),
          permission: `${PermissionActions.Create}${this.moduleKeyword}`,
        },
      ],
    });
  }

  ngOnInit(): void {}

  setCols() {
    const mappingService = AppInjector.get(IssueTypeMappingService);
    this.cols = [...mappingService.tableFields].map((x) => {
      return {
        ...x,
        name: this.localeService.translate(
          `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(this.moduleKeyword)}.fields.${x.key}.label`,
          x.name
        ),
        translationKeyPrefix:
          x.translationKeyPrefix ||
          `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(this.moduleKeyword)}.fields.${x.key}`,
      };
    });
  }
}
